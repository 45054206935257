// JStreamのAPIを使用し、低画質動画のURLを取得可能にする
// 参考: https://support.eq.stream.co.jp/hc/ja/articles/115008332087-ReadAPI%E5%88%A9%E7%94%A8%E6%96%B9%E6%B3%95
import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

export type LQMovieUrls = {
  movieUrlLq?: string // 低画質URL
  movieUrlSq?: string // 中画質URL
}

@Component
export default class LQMovieLoadable extends Vue {
  private innerCurrentMovieQuality: 'HQ' | 'SQ' | 'LQ' = Vue.prototype.$cookies.get('dataGdls').movieQuality || 'HQ'

  // 画質選択状態(HQ/SQ/LQ)
  protected get currentMovieQuality(): 'HQ' | 'SQ' | 'LQ' {
    return this.innerCurrentMovieQuality
  }

  protected set currentMovieQuality(quality: 'HQ' | 'SQ' | 'LQ') {
    this.innerCurrentMovieQuality = quality

    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['movieQuality'] = quality
    Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * 低画質動画のURLを取得する
   *
   * @param fileName 動画ファイル名(api_keywordsとして使用する)
   */
  protected async loadLQUrls(fileName: string): Promise<LQMovieUrls> {
    const http = this.initAxios()
    const res = await http.get(`/getMediaByParam/`, {
      params: { token: this.platformToken, api_keywords: fileName, type: 'json' },
    })
    const res_url = res.data.meta[0]?.movie_url

    const urls: LQMovieUrls = {}
    if (res_url?.mb_lq) urls.movieUrlLq = res_url.mb_lq.replace('http://', 'https://')
    if (res_url?.mb_sq) urls.movieUrlSq = res_url.mb_sq.replace('http://', 'https://')

    return urls
  }

  private initAxios() {
    return axios.create({
      baseURL: this.platformUrl,
      timeout: 180000,
    })
  }

  private get platformUrl() {
    return process.env.VUE_APP_MOVIE_PLATFORM_URL
  }

  private get platformToken() {
    return process.env.VUE_APP_MOVIE_PLATFORM_TOKEN
  }
}
