


























































import { Component, Watch, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import $ from 'jquery'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import VueRouter from 'vue-router'
import LQMovieLoadable, { LQMovieUrls } from '@/mixins/utils/LQMovieLoadable'

Component.registerHooks(['beforeRouteUpdate'])

export type Movie = {
  movieId: number
  movieName: string
  movieFilename: string
  movieUrl: string
  sortNum: number
  liked: boolean
  watched: boolean
}

export type MovieResult = {
  id: number
  movieId: number
}

@Component({
  components: {
    ButtonBase,
  },
  head: {
    script: [{ type: 'text/javascript', src: '/js/oneTagPlayer.js', body: true }],
    link: [{ rel: 'stylesheet', href: '/css/oneTagPlayer.css' }],
  },
})
export default class AssistMaterialVideo extends Mixins(LessonStudent, LQMovieLoadable) {
  private curriculumSUnitId = this.$route.params.curriculumSUnitId
  private curriculumSUnitName = ''
  private curriculumModeCode = this.$route.query.curriculumModeCode
  private publisherCode = this.$route.query.publisherCode
  private movies: Movie[] = []
  private selectedMovie: any = {}
  private resultMovies: MovieResult[] = []
  private isReport = this.$route.path.includes('report')

  private movieType = 'D'
  // TODO 授業モードのコードは固定で暫定対応（必要か要確認）
  private classModeCode = 'HJ'
  private classCategoryCode = 'HD'

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  private readonly subjectNames = { ko: '国語', su: '算数/数学', ei: '英語', ri: '理科', sh: '社会' }

  private get subjectName() {
    return this.subjectNames[this.subjectCode]
  }

  private noticeCurrentPageText = '導入動画視聴中'

  private get noticePageTitle() {
    return this.curriculumSUnitName
  }

  public async mounted(): Promise<void> {
    Vue.prototype.$logger.log('-- AssistMaterialVideo mounted')

    window.addEventListener('unhandledrejection', this.detectError, true)
    await this.loadDatas()
    this.noticeGroup()
  }

  private beforeDestroy() {
    window.removeEventListener('unhandledrejection', this.detectError)
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    Vue.prototype.$logger.info('-- AssistMaterialVideo beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private detectError(event: any): void {
    const message = event.reason.message
    if (
      message === 'The operation was aborted.' ||
      message.includes('The play() request was interrupted by a call to pause().')
    ) {
      alert(
        '動画の読み込みに失敗したか読み込み中の誤操作を検知しました。お手数ですが再度読み込みを行い動画の表示を待ってから再生をお願いします。'
      )

      // 後続のhandleError.tsでキャッチしているエラーイベントのリスナーをキャンセルする
      event.stopImmediatePropagation()
    }
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({
      currentPage: this.noticeCurrentPageText,
      pageTitle: `${this.subjectName}） ${this.noticePageTitle}`,
    })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private async loadDatas(): Promise<any> {
    const movieIdSelected = +this.$route.query.movieId
    const res = await Vue.prototype.$http.httpWithToken.get('curriculum_s_units_movies', {
      params: {
        curriculumSUnitId: this.curriculumSUnitId,
        movieType: this.movieType,
        classModeCode: this.classModeCode,
        classCategoryCode: this.classCategoryCode,
      },
    })
    this.curriculumSUnitName = res.data.meta.curriculumSUnitName
    this.$store.commit('drillsV3/setTitleResult', res.data.meta.curriculumSUnitName)
    this.movies = res.data.movies
    this.setSelectedMovie(movieIdSelected || this.movies[0].movieId)
  }

  @Watch('selectedMovie')
  onSelectedMovieChanged(newData: Movie, oldData: Movie): void {
    if (newData.movieId === oldData.movieId) return

    const index = this.movies.findIndex((movie: Movie) => movie.movieId === oldData.movieId)
    if (index !== -1) {
      // 直前の動画を視聴済にする
      oldData.watched = true
      this.movies.splice(index, 1, oldData)
    }

    try {
      this.createVideoPlayer()
    } catch (e) {
      if (e.message == 'window.CreateVideoPlayer is not a function') {
        // 外部プレイヤー(oneTagPlayer.js)のロード状態によって、エラーが発生する場合がある為、時間をおいて再実行
        setTimeout(() => this.createVideoPlayer(), 2000)
      } else {
        throw new Error(e)
      }
    }
  }

  // selectedMovie.movieNameの変更時、各画質のURLを取得し、ここに保持する
  private selectedMovieUrls: LQMovieUrls = {}

  // 画質変更によって動画URLが変更された場合はプレイヤーを作成しなおす
  onChangeMovieQuality(quality: 'HQ' | 'SQ' | 'LQ') {
    this.currentMovieQuality = quality
    this.createVideoPlayer()
  }

  // プレイヤー作成(url指定があれは優先。なけれは選択動画のデフォルトURLを使用する)
  private createVideoPlayer() {
    let url
    if (this.currentMovieQuality == 'LQ') url = this.selectedMovieUrls.movieUrlLq
    if (this.currentMovieQuality == 'SQ') url = this.selectedMovieUrls.movieUrlSq
    if (this.currentMovieQuality == 'HQ') url = this.selectedMovie.movieUrl

    // @ts-ignore
    window.CreateVideoPlayer({
      target: 'player',
      src: url,
      auto: 'off',
      //movieid: this.selectedMovie.movieName,
    })
  }

  private isSelected(id: number): boolean {
    return id === this.selectedMovie.movieId
  }

  private async setSelectedMovie(id: number): Promise<any> {
    if (!this.isReport) {
      const query = this.$route.query || {}
      this.$router.push({
        path: this.$route.path,
        query: {
          ...query,
          movieId: String(id),
        },
      })
    }

    const selectedMovie: any = this.movies.find((movie: Movie) => movie.movieId === id)
    this.selectedMovieUrls = await this.loadLQUrls(selectedMovie.movieFilename)

    // 選択中の画質の動画が存在しない場合、高画質に切り替える
    if (this.notFoundCurrentQualityMovie()) this.currentMovieQuality = 'HQ'

    // ここでインスタンス変数へ代入し、onSelectedMovieChangedを走らせる
    this.selectedMovie = selectedMovie

    // 直前の動画視聴履歴を完了で更新
    await this.setCompleted()
    if (this.isReport) return
    // 新しい動画視聴履歴を登録
    const params = {
      movie_id: this.selectedMovie.movieId,
      curriculum_s_unit_id: this.curriculumSUnitId,
      classModeCode: this.classModeCode,
      classCategoryCode: this.classCategoryCode,
      liked: this.selectedMovie.liked,
      subjectCode: this.subjectCode,
      curriculumModeCode: this.curriculumModeCode,
      publisherCode: this.publisherCode,
    }
    await Vue.prototype.$http.httpWithToken.post('/result_movies', { resultMovie: params }).then((res: any) => {
      this.resultMovies.push(res.data.resultMovie)
    })
  }

  /**
   * 選択中の画質の動画の存在チェック
   */
  private notFoundCurrentQualityMovie(): boolean {
    return (
      (this.currentMovieQuality == 'SQ' && !this.selectedMovieUrls.movieUrlSq) ||
      (this.currentMovieQuality == 'LQ' && !this.selectedMovieUrls.movieUrlLq)
    )
  }

  private isCompleted(id: number): boolean {
    const movie = this.movies.find((movie: Movie) => movie.movieId === id)
    return movie ? movie.watched : false
  }

  private get likedColortype(): string {
    return this.selectedMovie && this.selectedMovie.liked ? 'skyblue' : 'gray'
  }

  private async setLiked(): Promise<any> {
    const lastResultMovie = this.resultMovies.pop()
    if (lastResultMovie) {
      this.selectedMovie.liked = !this.selectedMovie.liked
      // 視聴中のの動画視聴履歴のいいねを更新
      await Vue.prototype.$http.httpWithToken
        .put(`/result_movies/${lastResultMovie.id}`, { resultMovie: { liked: this.selectedMovie.liked } })
        .then((res: any) => {
          this.resultMovies.push(res.data.resultMovie)
        })
    }
  }

  private async setCompleted(): Promise<any> {
    const lastResultMovie = this.resultMovies.pop()
    if (lastResultMovie) {
      await Vue.prototype.$http.httpWithToken.put(`/result_movies/${lastResultMovie.id}/completed`).then((res: any) => {
        this.resultMovies.push(res.data.resultMovie)
      })
    }
  }

  private async doTransition(): Promise<any> {
    await this.setCompleted()
    window.location.href = `/student/assist-material/select/${this.curriculumModeCode}`
  }
}
